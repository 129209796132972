<template>
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Gspqualitytrack:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Gspqualitytrack:Save'">保存</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-form ref="basicForm" :model="dataSource" :rules="basicValidate">
                <el-tabs type="border-card" v-model.trim="activeName">
                    <el-tab-pane label="" name="tabbasic">
                        <el-collapse v-model.trim="collapseItem">
                            <el-collapse-item title="基本信息" name="basicinfo">
                                <el-form-item v-if="!config.isAdd">
                                    <el-col :span="3" class="form-title">单据号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.GspRecordNo" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="CustomerCode">
                                            <LookPopup v-model="dataSource.CustomerCode"
                                                dataTitle="货主选择"
                                                dataUrl='omsapi/customerinfo/getlist'
                                                syncField='CustomerCode'
                                                @syncFieldEvent="syncCustomerEvent"
                                                rules="basicValidate.CustomerCode"
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">货主名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.CustomerName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>客商编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="VendorCode">
                                            <LookPopup v-model="dataSource.VendorCode"
                                                dataTitle="客商选择"
                                                dataUrl='omsapi/customervendor/getlist'
                                                syncField='VendorCode'
                                                @syncFieldEvent="syncVendorEvent"
                                                rules="basicValidate.VendorCode"
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">客商名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.VendorName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品编码：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNo">
                                            <LookPopup v-model="dataSource.ProductNo"
                                                dataTitle="商品选择"
                                                dataUrl='omsapi/product/getproductlist'
                                                syncField='ProductNo'
                                                @syncFieldEvent="syncProductEvent"
                                                rules="basicValidate.ProductNo"
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.ProductNo" 
                                                suffix-icon="el-icon-search" readonly
                                                ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">商品中文名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductName" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">规格：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductSpec"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成厂家：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductCompany"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>批号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductBatchCode">
                                            <el-input v-model.trim="dataSource.ProductBatchCode"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNum">
                                            <el-input v-model.trim="dataSource.ProductNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">金额：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductMoney"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">质检：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductInspector"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">包装单位：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductUnit"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">供货单位联络人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductContact"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">供货单位联络电话：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductPhone"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>质量问题：</el-col>
                                    <el-col :span="21">
                                        <el-form-item prop="QualityTrackContent">
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.QualityTrackContent"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="处理信息" name="basicinfo1">
                                <el-form-item>
                                    <el-col :span="3" class="form-title">处理时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.ProcessTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                            <!-- <el-input v-model.trim="dataSource.ProcessTime"></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">处理状态：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <!-- <el-input v-model.trim="dataSource.ProcessStatus"></el-input> -->
                                            <el-select v-model="dataSource.ProcessStatus" :maxlength="200">
                                                <el-option v-for="item in processStatusList" 
                                                    :key="item.Value" 
                                                    :label="item.Label" 
                                                    :value="item.Value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">处理人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <LookPopup v-model="dataSource.ProcessPerson"
                                                dataTitle="用户选择"
                                                dataUrl='omsapi/sysuser/getlist'
                                                syncField='UserName'
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">处理结果：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.ProcessContent"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">备注：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.ProcessRemark"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">制单人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <LookPopup v-model="dataSource.CreatorName"
                                                dataTitle="用户选择"
                                                dataUrl='omsapi/sysuser/getlist'
                                                syncField='UserName'
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">制单时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.CreateTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                            <!-- <el-input v-model.trim="dataSource.CreateTime"></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </el-form> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            var validateCarrierName = (rule, value, callback) => {
                if (!value) return callback(new Error('承运商名称不能为空'));
                callback();
            };
            var validateWarehouseName = (rule, value, callback) => {
                if (!value) return callback(new Error('仓库名称不能为空'));
                callback();
            };
            var validateOrderTime = (rule, value, callback) => {
                if (!value) return callback(new Error('下单时间不能为空'));
                callback();
            };
            return {
                collapseItem: ['basicinfo','basicinfo1',''],
                basicValidate: {
                    CustomerCode:[{ required: true, message: '货主不能为空', trigger: 'blur' }],
                    VendorCode:[{ required: true, message: '客商不能为空', trigger: 'blur' }],
                    ProductNo:[{ required: true, message: '商品不能为空', trigger: 'blur' }],
                    ProductBatchCode:[{ required: true, message: '批号不能为空', trigger: 'blur' }],
                    ProductNum:[{ required: true, message: '数量不能为空', trigger: 'blur' }],
                    QualityTrackContent:[{ required: true, message: '质量问题不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false,
                dataKey:'',
                dataSourceKey:null
            },
        },
        mounted: function () {
            this.initialBaseList();
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/gspqualitytrack/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            initialBaseList(){
                var _this = this;
                _this.$ajax.send("omsapi/base/getlistbyenumname", "get", {name:'GspProcessStatusCode'}, (data) => {
                    if(data.IsSuccess) 
                        _this.processStatusList=data.Result;
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/gspqualitytrack/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit(_this.dataKey+'reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncVendorEvent:function(row){
                if(row){
                    this.dataSource.VendorName = row.VendorName;
                }
            },
            syncCustomerEvent:function(row){
                if(row){
                    this.dataSource.CustomerName = row.CustomerName;
                }
            },
            syncProductEvent:function(row){
                if(row){
                    this.dataSource.ProductName = row.ProductName;
                }
            },
        },
        components: {
        }
    }
</script>